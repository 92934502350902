.Offline {
	padding: 12px 24px;
	background: #E67E22;
	color: white;
	&-text {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		height: 21px;
		color: #FFFFFF;
	}
	&-link {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 150%;
		text-decoration-line: underline;
		color: #FFFFFF;
	}
}

.PageHeader {

	.p-badge {
		min-width: 21px !important;
		height: 21px !important;
		line-height: 21px !important;
		border-radius: 30px;
		box-shadow: 0 0 0 2px #FFFFFF;
		border: none;
	}

}

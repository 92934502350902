$colors: (
    "blue": #2196F3,
    "green": #4caf50,
    "yellow": #FBC02D,
    "cyan": #00BCD4,
    "pink": #E91E63,
    "indigo": #3F51B5,
    "teal": #009688,
    "orange": #F57C00,
    "bluegray": #607D8B,
    "purple": #9C27B0
);

$gray-25:  #FCFCFD;
$gray-50: #F9FAFB;
$gray-100: #F2F4F7;
$gray-200: #EAECF0;
$gray-300: #D0D5DD;
$gray-400: #98A2B3;
$gray-500:  #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1D2939;
$gray-900: #101828;

$primary-25: #FCF2F3;
$primary-50: #F8DDE0;
$primary-100: #F1BBC1;
$primary-200: #EA99A2;
$primary-300: #E37783;
$primary-400: #DC5564;
$primary-500: #D53345;
$primary-600: #CE1127;
$primary-700: #A40D1F;
$primary-800: #7B0A17;
$primary-900: #52060F;

$secondary-25: #F3F5F7;
$secondary-50: #DAE0E6;
$secondary-100: #B6C1CD;
$secondary-200: #91A2B5;
$secondary-300: #6D839C;
$secondary-400: #486484;
$secondary-500: #24456B;
$secondary-600: #002653;
$secondary-700: #002047;
$secondary-800: #001B3B;
$secondary-900: #00152F;


// Theme Specific Variables
$primaryColor: $primary-600;
$primaryDarkColor: $primary-700;
$primaryDarkerColor: $primary-800;
$primaryTextColor: #ffffff;
$bg-secondary-color: #FFFFFF;
$bg-primary-color: #EFF3F8;
$text-neutral-black: #3F3F46;
$text-neutral-grey: #71717A;
$text-subtle-grey: #94949E;

// Mandatory Designer Variables
@import 'variables/general';
@import 'variables/form';
@import 'variables/button';
@import 'variables/panel';
@import 'variables/data';
@import 'variables/overlay';
@import 'variables/message';
@import 'variables/menu';
@import 'variables/media';
@import 'variables/misc';

:root {
    --surface-a:#ffffff;
    --surface-b:#f8f9fa;
    --surface-c:#e9ecef;
    --surface-d:#dee2e6;
    --surface-e:#ffffff;
    --surface-f:#ffffff;
    --text-color:#495057;
    --text-color-secondary:#F2A6B3;
    --primary-color: $primaryColor;
    --primary-color-text:#ffffff;
    --bg-primary:$bg-primary-color;
    --font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --surface-0: #ffffff;
    --surface-50: #FAFAFA;
    --surface-100: #F5F5F5;
    --surface-200: #EEEEEE;
    --surface-300: #E0E0E0;
    --surface-400: #BDBDBD;
    --surface-500: #9E9E9E;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-800: #424242;
    --surface-900: #212121;
    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #EEEEEE;
    --gray-300: #E0E0E0;
    --gray-400: #BDBDBD;
    --gray-500: #9E9E9E;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --content-padding:#{$panelContentPadding};
    --inline-spacing:#{$inlineSpacing};
    --border-radius:#{$borderRadius};
    --surface-ground:#f8f9fa;
    --surface-section:#ffffff;
    --surface-card:#ffffff;
    --surface-overlay:#ffffff;
    --surface-border:#dee2e6;
    --surface-hover:#e9ecef;
}

.QuotationMargin {
	&-line {
		&-icon {
			color: #667085;
			font-size: 14px;
		}
		&-input {
			background: #FFFFFF;
			border: 1px solid #EAECF0;
			border-radius: 8px;
			height: 50px;
			i {
				font-size: 12px;
			}
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 130%;
			color: #98A2B3;
		}
	}
}

.QuotationModal {
	&-input {
		&-dropdown {
			background: #EAECF0 !important;
			border-radius: 4px !important;
			.p-dropdown-label {
				height: 100%;
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 130%;
				color: #475467;
			}

			.p-dropdown-trigger-icon {
				font-size: 14px;
			}
		}
	}
	.p-dropdown-panel {
		margin-top: 7px;
		width: 200px;
		background: #1D2939;
		padding: 0 8px;
		transform: translateX(-40px);

		.p-dropdown-items {
			padding: 6px 0 !important;
		}

		.p-highlight {
			background-color: #475467! important;
		}
		.p-dropdown-item {
			padding: 8px !important;
			margin: 2px 0! important;
			border-radius: 4px !important;
			font-family: 'Rubik' !important;
			font-style: normal !important;
			font-weight: 400 !important;
			font-size: 16px !important;
			line-height: 130% !important;
			color: #F9FAFB !important;
		}
	}
}

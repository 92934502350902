.ProductDetails {

	.p-dialog {
		&-mask {
			z-index: 2
		}
		&-footer {
			padding:  0 24px 24px 24px;
		}

	}

	&-footer {
		padding: 24px;
	}


	&-footer {
		background: white;
		&-actions {
			.p-inputtext {
				width: 64px;
				height: 47px;
				border: none;
				background: #F3F5F7;
				color: black;
				text-align: center;
			}

			.p-inputnumber-button {
				background: #F3F5F7;
				color: black;
				border: none;
				&:active, &:hover {
					background: #e4e6e8 !important;
					color: black !important;
					border: none !important;
				}
				&-disabled {
					color: #C8C8C8;
					cursor: default;
					&:hover, &:active {
						background: #F3F5F7 !important;
						color: #C8C8C8 !important;
					}
				}
			}
		}
	}
}

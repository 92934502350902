.PublicCatalog {
	max-width: 1140px;
	width: calc(100% - 64px);

	&__filter {
		.p-input-icon-left {
			width: 35%;
		}
		.p-inputtext {
			width: 100%;
			height: 44px;
		}
	}
	&__content {
		max-height: 680px;

		$cursorColor: #D0D5DD;

		.p-datatable {
			&-wrapper {
				&::-webkit-scrollbar-thumb {
					background: $cursorColor !important;
					height: 60px;
					border: 3px solid white;
					border-radius: 10px;
				}
				&::-webkit-scrollbar {
					display: block;
					width: 12px;
					background-color: #ffffff;
				}
			}

			.category {
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				line-height: 150%;
				text-transform: uppercase;
				color: #000000;
			}

			.price-header {
				justify-content: end !important;
			}

			.small-text {
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				color: #667085;
			}

			th {
				background: #002653 !important;
				.p-column-title {
					color: white;
					font-family: 'Rubik';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 150%;
				}


				.p-sortable-column-icon {
					color: white !important;
				}
			}
		}


	}

}

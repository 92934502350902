.Order {
	padding: 32px 24px 296px 24px;
	&-title {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		color: #000000;
	}
	&-form {
		&-title {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			display: flex;
			align-items: center;
			color: #101828;
		}
		&-input {
			label {
				display: block;
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 115%;
				color: #667085;
			}

			.p-dropdown {
				border: 1px solid #d0d5dd;
				.p-inputtext {
					padding: 14px 14px;
					line-height: 130%;
					color: #98A2B3;
				}
			}

			.p-inputtext {
				width: 100%;
				height: 50px;
				padding: 14px;
			}
		}
		&-warning {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 130%;
			display: flex;
			align-items: center;
			color: #98A2B3;
		}

		hr {
			border: none;
			height: 1px;
			background: #EAECF0;
		}

		&-check {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 130%;
			color: #667085;
			.p-checkbox {
				&-box {
					border-radius: 4px !important;
				}
			}
		}

		&-disabled {
			opacity: 30%;
			cursor: default;
			user-select: none;
		}

		&-dropdown {
			i {
				left: 10px;
				top: 15px;
				color: #98A2B3;
			}
			&>label {
				display: block;
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 115%;
				color: #667085;
			}
			.p-dropdown {
				width: 100%;
				&-label {
					display: flex;
					align-items: center;
				}
			}
		}
	}
}

.Filter {
	&-section {
		margin-bottom: 12px;
		&-title {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #000000;
		}
		&-input {
			&-line {
				height: 44px;
				margin-bottom: 12px;

				&-checkbox-label {
					font-family: 'Rubik';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 130%;
					text-align: center;
					color: #667085;
				}

				&-text {
					font-family: 'Rubik';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 150%;
					color: #667085;
				}

				.p-dropdown {
					&.p-disabled {
						opacity: 30%;
					}
					display: flex;
					align-items: center;
					height: 100%;
					border-width: 1px;
					width: 100px;
					&-activated {
						.p-dropdown-clear-icon {
							right: 11px !important;
							margin-right: 0;
						}

						.p-dropdown-label {
							padding-right: 32px;
						}

						.p-dropdown-trigger {
							display: none;
						}
					}

					&-label {
						height: min-content;
						line-height: 100%;
						font-family: 'Rubik';
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						text-align: right;
						color: #000000;
					}

					&-variant {
						.p-dropdown-label {
							padding-left: 16px;
							text-align: left;
							font-weight: normal;
						}
					}
					&-clear-icon {
						margin-top: -5px;
						font-size: 10px;
						margin-right: -5px;
					}
					&-trigger {
						width: 2rem;
						&-icon {
							font-size: 10px;
							color: #95A5A6;
						}
					}
				}
			}
		}
	}
}

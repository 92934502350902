.Search {
	&-input {
		&-wrapper {
			padding: 24px 16px;
			background: #F2F4F7;
			border-radius: 10px;
		}

		&-example-text {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #98A2B3;
		}
		&-infotext {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 130%;
			display: flex;
			align-items: center;
			color: #98A2B3;
		}

		&-autocomplete {
			position: relative;
			.p-autocomplete {
				height: 50px;
				width: 100%;
				&-input {
					width: 100%;
					padding: 0 34px;
				}
			}
			&-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				color: #98A2B3;
				position: absolute;
				height: 50px;
				z-index: 1012;
				padding: 8px;
			}
		}
	}

	&-filter-text {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
	}

	&-image-container {
		display: grid;
		grid-gap: 16px;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 150px 150px;
		&-item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			grid: 1/span1;
			background: #F2F4F7;
			padding: 16px 0;
			border-radius: 10px;
			img {
				width: 70px;
				height: 70px;
			}
			span {
				font-family: 'Montserrat';
				font-style: normal;
				font-weight: 700;
				font-size: 14px;
				line-height: 130%;
				display: flex;
				align-items: center;
				text-transform: uppercase;
				color: #000000;
			}
		}
	}
}



.p-autocomplete-panel {
	width: 295px;
	z-index: 1012;
	&-custom {
		&-label {
			word-break: break-word;
			overflow-wrap: anywhere;
			white-space: normal;
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #667085;
		}
	}
}


.CustomDropdown {
	&-label {

		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		/* identical to box height, or 30px */

		display: flex;
		align-items: center;
		text-transform: uppercase;
		margin: 0;
		height: 44px;
		padding: 0;
		color: #000000;
	}



	&-dialog {
		&-option {
			&-selected {
				background: #F2F4F7;
			}
			cursor: pointer;
			height: 48px;
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 150%;
			padding: 12px 16px;
			border-radius: 5px;
			&:not(&-selected):hover {
				background: #fdfafa;
			}
		}
		width: calc(100vw - 48px);
		margin-top: 140px !important;
	}

}

.UserBadge {
	&-avatar {
		background: #002653;
		color: white;
	}
	&-name {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: #000000;
	}
	&-email {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: #98A2B3;
	}
}

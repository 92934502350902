.NavigationMenu {
	height: calc(100% - 64px);
	width: 100vw;
	background: white;
	z-index:1;
	padding: 32px 24px;

	hr {
		height: 1px;
		background: #EAECF0;
		border:none;
		margin: 24px 0;
	}

	&-items {

		&-item {
			padding: 12px 16px;
			margin-bottom: 12px;
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 700;
			font-size: 16px;
			line-height: 150%;
			text-transform: uppercase;
			color: #000000;
			&:last-child {
				margin: 0;
			}

			&-disabled {
				opacity: 50%;
			}
		}

	}
	&-tos {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		text-decoration-line: underline;
		margin-top: 24px;
		color: #000000;
	}
	.p-button {
		background: #002653;
		border-radius: 8px;
		border: none;
		height: 56px;
		color: white;
		&-custom-label {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 700;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			text-transform: uppercase;
			color: #FFFFFF;
		}
		&:active, &:hover {
			background: #002858 !important;
		}
	}
}

.Quotation {
	padding: 32px 24px 293px 24px;
	&-title {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		color: #000000;
	}
	&-products {
		&-extra {
			&>span {
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;
				text-align: right;
				text-decoration-line: underline;
				color: #CE1127;
			}
			&-label {
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 115%;
				color: #667085;
			}

			&-input {
				span {
					font-family: 'Rubik';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 130%;
					color: #101828;
				}
				background: #FFFFFF;
				border: 1px solid #EAECF0;
				border-radius: 8px;
				height: 50px;
				i {
					font-size: 12px;
				}
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 130%;
				color: #98A2B3;
			}
		}
	}
	&-card {
		&-details {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 130%;
			display: flex;
			align-items: center;
			color: #98A2B3;
		}
	}

}

.QuotationExtraModal {
	.p-input-icon-left {
		i {
			font-size: 14px !important;
			color: #95A5A6 !important;
		}
		.p-inputnumber {
			input {
				padding-left: 28px;
			}
		}
	}
	&-price {
		&-label {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 150%;
			text-align: center;
			color: #000000;
		}
		&-price {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			text-align: center;
			color: #000000;
		}
	}
}

.SwippableModal {
	&-mask {
		position: absolute;
		top: 0;
		z-index: 990;
		height: 100vh;
		width: 100vw;
		background-color: rgba(0,0,0,0.3);
		overflow: hidden;
	}
	&-wrapper {
		position: fixed;
		bottom: 0;
		width: 100vw;
		height: auto;
		margin-bottom: -100%;
		border-radius: 36px 36px 0 0;
		background: white;
		padding-bottom: 5vh;
		&-handle {
			height: 40px;
			&::before {
				display: block;
				content: "";
				width: 40px;
				height: 4px;
				border-radius: 20px;
				background: #D0D5DD;
			}
		}
	}
}

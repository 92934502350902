.p-dialog-mask {
	z-index: 1050;
	.p-dialog {
		width: 342px;
		&-header {
			padding: 16px;
		}
	}

	.DetailModal {
		&-line {
			display: flex;
			justify-content: space-between;
			&-label {
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 150%;
				color: #98A2B3;
			}
			&-price {
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;
				color: #98A2B3;
			}
		}
		&-lastLine {
			display: flex;
			justify-content: space-between;
			&-label {
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 150%;
				color: #000000;
			}
			&-price {
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 150%;
				color: #000000;
			}
		}
	}
}

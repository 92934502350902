.MyCart {
	padding: 32px 24px 243px 24px;
	&-title {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		color: #000000;
	}
}

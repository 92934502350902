.AgencySelector {
	height: 44px;
	background: #002653;
	padding: 12px 24px;
	color: white;
	&-name {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 19px;
	}
	&-modal {
		&-title {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 150%;
			display: flex;
			align-items: center;
			text-transform: uppercase;
			color: #000000;
			padding-bottom: 24px;
		}
		&-line {
			margin-bottom: 24px;
			&-label {
				display: inline-block;
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				height: auto;
				line-height: 20px;
				color: #667085;
			}
		}

		.p-radiobutton-icon {
			height: 8px !important;
			width: 8px !important;
		}
	}
}

.MyAgencies {
	padding: 32px 0;

	&-subtitle {
		padding: 0 24px;
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 150%;
		color: #101828;

	}

	&-item {
		padding: 24px;
		background: white;
		&-name {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 115%;
			color: #667085;
		}

		&-address, &-code {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 130%;
			color: #101828;
		}
	}

	label {

		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 115%;
		color: #667085;
	}

}

.ProductTechnicalInformations {

	&-header, &-content {
		padding: 24px;
	}
	&-header {
		background: white;
		&-brand {
			img {
				height: 24px;
				width: auto;
			}
		}
		&-profile {
			img {
				height: 200px;
				width: auto;
			}
		}
		&-type {
			font-family: 'Montserrat';
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 150%;
			text-transform: uppercase;
			color: #000000;

		}
		&-information {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #667085;
		}
	}

	&-content {
		&-line {
			&-label {
				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				color: #667085;
			}

			&-value {

				font-family: 'Rubik';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 150%;
				color: #000000;
			}
		}
	}
}

.Catalog {
    &__filter {
        height: 44px;

	    .p-dropdown {
		    background: transparent;
		    &-label {

		    }
		    &-trigger {
			    color: #000;
		    }
	    }
    }
	&__content {
		height: calc(100% - 44px);
		padding-bottom: 165px;
	}
}

.MyOrders {
	&-title {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		color: #000000;
	}
	&-subtitle {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 130%;
		display: flex;
		align-items: center;
		color: #98A2B3;
	}
	&-order {
		&-title {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			color: #000000;
		}
		&-agency, &-price {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #98A2B3;
		}
	}
}

.MyQuotations {
	&-title {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 150%;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		color: #000000;
	}
	&-subtitle {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 130%;
		display: flex;
		align-items: center;
		color: #98A2B3;
	}
	&-order {
		&-title {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			color: #000000;
		}
		&-agency, &-price {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 150%;
			color: #98A2B3;
		}
	}
	.p-tabview {
		&-nav {
			background: transparent !important;
			&-link {
				background: transparent !important;
				text-align: center !important;
			}

			li {
				width: 50%;
			}

			&-link[aria-selected=true] {
				border-color: #878787 !important;
			}
			&-container {
				padding: 0 24px !important;
			}
		}
		&-selected {
			.p-tabview-title {
				color: black;
			}
		}
		&-ink-bar {
			background-color: black !important;
		}

		&-panels {
			padding: 2rem 0 0 0 !important;
			background: transparent !important;
		}
	}



}

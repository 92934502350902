.p-tag {
    background: $badgeBg;
    color: $badgeTextColor;
    font-size: $badgeFontSize;
    font-weight: $badgeFontWeight;
    padding: $tagPadding;
    border-radius: $borderRadius;

    &.p-tag-success {
        background-color: #C4FCCD;
        color: #157831;
    }

    &.p-tag-info {
        background-color: $infoButtonBg;
        color: $infoButtonTextColor;
    }

    &.p-tag-warning {
        background-color: #FCE9C4;
        color: #785715;
    }

    &.p-tag-danger {
        background-color: #FCC4C4;
        color: #781515;
    }

    .p-tag-icon {
        margin-right: $inlineSpacing * 0.5;
        font-size: $badgeFontSize;
    }
}

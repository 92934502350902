.ProfileSections {
  padding: 32px 24px 296px 24px;

  &-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #000000;
  }

  &-section {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    &__items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #101828;
      border-bottom:  1px solid #EAECF0;
    }
  }
}
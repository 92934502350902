@use 'src/theme/variables' as *;

.CatalogItem {
    &__price {
        border-top: 1px solid $gray-200;
	    &-quantity {
		    font-family: 'Rubik';
		    font-style: normal;
		    font-weight: 400;
		    font-size: 16px;
		    line-height: 150%;
		    text-align: center;
		    color: #667085;
	    }
	    &-promo {
		    background:#1abc9c;
		    padding: 4px 8px;
		    border-radius:4px;
		    color: white !important;
	    }
    }
}

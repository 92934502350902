.InformationPersonal {
  padding: 32px 24px 296px 24px;

  &-subtitle {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #101828;

  }

	label {

		font-family: 'Rubik';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 115%;
		color: #667085;
	}

}
